<template>
  <div>
    <headerTab></headerTab>
    <div class="ab_content1">
      <div class="offine_title1">
        <span style="color:#FF3E46">*</span>全部转让款项是包含居间报酬的，在转让过程中居间报酬将由受让方代缴，转让方所获取的转让款为：实际转让款=总额-居间报酬
      </div>
      <div class="ab_list" v-if="queryList.status!=1">
        <div>订单总额:<span>￥{{tableData.shares_amount}}</span></div>
        <div>居间税率:{{tableData.sales_tax_rate_text}}</div>
      </div>
      <div v-else>
        <div class="ab_list">
          <div>订单:<span style="font-size:16px;font-weight:400;color:#07C160">￥{{tableData.trade.trade_no}}</span></div>
          <div>居间税率:{{tableData.sales_tax_rate_text}}</div>
        </div>
        <div class="ab_list">
          <div>转让工分:<span style="font-weight:400;">￥{{tableData.shares}}</span></div>
          <div>价值:<span style="font-weight:400;">{{tableData.shares_price}}</span>/股</div>
        </div>
        <div class="ab_list1">订单总额:<span style="color:#585858;font-size:16px;font-weight:400">￥{{tableData.shares_amount}}</span></div>
      </div>
      <div class="ab_list1">实际转让款:<span>￥{{tableData.shares_sales}}</span></div>
      <div class="pay_title">收款账户</div>
      <div class="pay_info" v-if="tableData.trade.remit_info">
        <div>账户主体:{{tableData.trade.remit_info[0].userName}}</div>
        <div>对公账号:{{tableData.trade.remit_info[0].number}}</div>
        <div>开户行详情:{{tableData.trade.remit_info[0].bank}}</div>
      </div>
      <div class="pay_info" v-else>
        <div>账户主体:{{tableData1.user.union_info.bank.userName}}</div>
        <div>对公账号:{{tableData1.user.union_info.bank.number}}</div>
        <div>开户行详情:{{tableData1.user.union_info.bank.bank}}</div>
      </div>
      <div v-if="queryList.status!=1">
        <div class="pay_title" style="padding-top:50px">打款凭证</div>
        <div class="imgList" v-for="(item,index) in tableData.trade.remit_info" :key="index">
          <div>{{item.remit_at}}</div>
          <img class="imgBox" @click="ImagePreview(item.voucher[0].url)" :src="item.voucher[0].url" />
        </div>
      </div>

    </div>

    <div v-if="queryList.status!=1&&!queryList.look" class="infoBtn" @click="submit">提交</div>
    <div class="item_btn" v-if="queryList.look">
      <div @click="submitPay(1)">收款异常</div>
      <div style="background:#07C160" @click="submitPay">确认收款</div>
    </div>
  </div>
</template>
<script>
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import { Toast } from "vant";
import { ImagePreview } from "vant";
import { Dialog } from "vant";
import { gathering_api, dealDetails_api } from "@/api/deal";
export default {
  data() {
    return {
      showPicker: false,
      imgWidth: 645,
      fileList: [],
      signtureShow: false,
      img: "",
      imgConfig: "{ useCdnDomain: true }",
      iconShow: false,
      imgToken: "",
      queryList: {},
      tableData: {
        trade: {}
      },
      tableData1: {}
    };
  },
  components: {
    headerTab
  },
  created() {
    document.title = "打款详情";
    this.getToken();
    this.getHome();
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
  },
  methods: {
    // 确认收款
    submitPay(index, status) {
      if (index == 1) {
        if (status == 1) {
          this.$router.push(
            `./moneyConfirm?index=${this.tableData.trade.trade_id}&id=${
              this.tableData.sales_id
            }&status=1`
          );
        } else {
          this.$router.push(`./abnormal?id=${this.tableData.trade.trade_id}`);
        }
      } else {
        this.queryList.look = false;
        this.$forceUpdate();
      }
      console.log(this.queryList.look);
    },
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData1 = res.data;
        }
      });
    },
    submit() {
      Dialog.confirm({
        title: "提示",
        message: "确定收款无误"
      })
        .then(() => {
          let data = {
            receipt_state: 1
          };
          gathering_api(data, this.queryList.index).then(res => {
            if (res.code == 0) {
              Toast({
                message: "操作成功",
                icon: "success",
                duration: 1000
              });
              this.$router.go(-1);
            }
          });
        })
        .catch(() => {
          console.log(1);
        });
    },
    ImagePreview(img) {
      ImagePreview([img]);
    },
    async getDetails(id) {
      let res = await apiCommon.SellingDeal_api(id);
      if (res.code == 0) {
        this.tableData = res.data;
        this.$forceUpdate();
      }
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {},
    // 上传凭证
    beforeRead(e) {
      console.log(e, "file");
      let img = e;
      this.upload(img);
    },
    async upload(img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      this.fileList.push({ url: this.imgUrl });
      console.log(this.fileList, this.imgUrl, "23213");
    },
    oversize() {},
    imgBtnClone(index, id) {
      console.log(index, "11");
      this.fileList.splice(id, 1);
    }
  }
};
</script>
<style>
.item_btn {
  display: flex;
  margin-top: 20px;
  margin-left: 120px;
  margin-bottom: 30px;
}
.item_btn div {
  width: 230px;
  height: 64px;
  background: #ff3e46;
  box-shadow: 3px 3px 10px rgba(255, 62, 70, 0.35);
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 64px;
  font-size: 25px;
  border-radius: 30px;
  margin-right: 20px;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  /* margin-top: 10px; */
}
.header_contract div {
}
.infoBtn {
  width: 388px;
  height: 90px;
  background: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height: 90px;
  font-size: 35px;
  color: #fff;
  margin: 100px auto 30px;
}

.userTitle {
  color: #0f87ff;
  font-size: 24px;
  letter-spacing: 2.5px;
  line-height: 32px;
}
.ab_content1 {
  width: 620px;
  margin: 100px auto 60px;
  font-size: 35px;
  color: #707070;
}
.offine_title1 {
  font-size: 24px;
  margin-top: 30px;
  font-weight: 400;
  margin-bottom: 30px;
}
.moneyConfirm span {
  font-size: 24px;
}
.formList {
  margin-top: 30px;
  font-size: 26px;
  font-weight: 400;
}
.van-uploader__upload {
  width: 162px;
  height: 162px;
  background: #f1f8ff;
  border: 1px solid #63b0ff;
  opacity: 1;
  border-radius: 5px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 100px;
  height: 100px;
}
.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.ab_content textarea {
  width: 620px;
  height: 300px;
}
.ab_list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;
  margin: 56px auto 28px;
  font-size: 30px;
}
.ab_list1 {
  display: flex;

  padding-bottom: 30px;
  margin: 56px auto 28px;
  margin-left: 270px;
  font-size: 30px;
}
.ab_list span {
  color: #ff3e46;
  font-size: 40px;
  font-weight: 800;
}
.ab_list1 span {
  color: #ff3e46;
  font-size: 40px;
  font-weight: 800;
}
.pay_title {
  font-size: 40px;
  color: #0f87ff;
}
.pay_info {
  padding-left: 53px;
  font-size: 30px;
  color: #585858;
  padding-bottom: 48px;
  border-bottom: 1px solid #ececec;
}
.pay_info div {
  margin-top: 50px;
}
.imgList {
  display: flex;
  line-height: 150px;
  font-size: 25px;
}
.imgBox {
  width: 150px;
  height: 150px;
  margin-left: 50px;
}
</style>
